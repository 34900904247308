<template>
    <v-container>
        <template v-if="$vuetify.breakpoint.mdAndUp">
            <amp-banner v-if="isEditor || isAuthor" class="my-4" />
            <welcome-banner v-else class="my-4" />
        </template>

        <announcements-client-selector
            v-if="isReseller"
            :clients="clients"
            :loading="isLoading"
            class="px-0"
        />

        <v-container class="px-0">
            <v-row class="justify-space-between">
                <v-col cols="6">
                    <div class="text-h6 text-md-h5 font-weight-bold">
                        All Amps
                    </div>
                </v-col>
                <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
                    <v-switch
                        v-model="showArchived"
                        :disabled="isLoading"
                        label="Show Archived"
                        class="mt-1"
                        hide-details
                        inset
                    />
                    <v-btn
                        class="white ml-4"
                        title="Create Amp"
                        :href="newAnnouncementLink"
                        :small="$vuetify.breakpoint.smAndDown"
                    >
                        <v-icon :left="$vuetify.breakpoint.mdAndUp" small>
                            plus
                        </v-icon>
                        {{ $vuetify.breakpoint.mdAndUp ? 'Create Amp' : null }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <announcements-list
            :announcements="announcements"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0"
            @update="update"
        />

        <paging
            :total="total"
            :default-per-page="perPage"
            :loading="isUpdating"
            class="main-background px-0"
        />

        <training-section class="mt-10" />

        <press-cable-user-welcome class="mt-10" />

        <training-link v-if="canSeeTrainingLink && !isLoading" class="mt-4" />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { AmpBanner } from '@/components/AmpBanner';
import { WelcomeBanner } from '@/components/WelcomeBanner';
import { Paging } from '@/components/Paging';
import { TrainingSection, TrainingLink } from '@/components/Training';
import { AnnouncementsClientSelector } from '@/components/AnnouncementsClientSelector';
import { AnnouncementsList } from '@/components/Announcements/AnnouncementsList';
import { PressCableUserWelcome } from '@/components/PressCableUserWelcome';

import type { Announcement } from '@/types/Announcement';
import type { ClientUser } from '@/types/User';

import { ListPaged, Training, UserId } from '@/mixins';

@Component({
    components: {
        AmpBanner,
        AnnouncementsClientSelector,
        AnnouncementsList,
        Paging,
        TrainingSection,
        TrainingLink,
        PressCableUserWelcome,
        WelcomeBanner
    },
    computed: {
        ...mapState('clients', ['clients']),
        ...mapGetters('user', ['isAuthor', 'isEditor', 'isReseller'])
    }
})
export default class Amps extends mixins(ListPaged, Training, UserId) {
    isEditor!: boolean;
    isReseller!: boolean;
    clients!: ClientUser[];

    announcements: Announcement[] = [];

    endpoint = '/announcements';

    autoRedirectForArchvies = true;

    get queryParams() {
        return {
            user_id: this.userId,
            limit: this.perPage,
            page: 1,
            sort: 'submitted',
            asc: 0,
            showArchived: 0,
            showList: 1
        };
    }

    get showArchived() {
        return this.$route.query.showArchived === '1';
    }

    set showArchived(showArchived: boolean) {
        if (this.autoRedirectForArchvies) {
            this.autoRedirectForArchvies = false;
        }

        this.$router.push({
            path: this.$route.path,
            query: {
                ...this.$route.query,
                page: void 0,
                showArchived: showArchived ? '1' : void 0
            }
        });
    }

    get newAnnouncementLink() {
        return ['/announcements/create', this.activeUserId]
            .filter(Boolean)
            .join('?user_id=');
    }

    onData(data: {
        length: number;
        announcements: Announcement[];
        clients: Record<string, string>;
    }) {
        const { announcements, length, clients } = data;

        if (announcements.length === 0 && this.autoRedirectForArchvies) {
            this.showArchived = true;
        }

        this.announcements = announcements;

        this.total = length;

        this.$store.dispatch('clients/set', clients);
    }
}
</script>
