import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[(_vm.$vuetify.breakpoint.mdAndUp)?[(_vm.isEditor || _vm.isAuthor)?_c('amp-banner',{staticClass:"my-4"}):_c('welcome-banner',{staticClass:"my-4"})]:_vm._e(),(_vm.isReseller)?_c('announcements-client-selector',{staticClass:"px-0",attrs:{"clients":_vm.clients,"loading":_vm.isLoading}}):_vm._e(),_c(VContainer,{staticClass:"px-0"},[_c(VRow,{staticClass:"justify-space-between"},[_c(VCol,{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-h6 text-md-h5 font-weight-bold"},[_vm._v(" All Amps ")])]),_c(VCol,{staticClass:"d-flex flex-grow-1 justify-end",attrs:{"cols":"auto"}},[_c(VSwitch,{staticClass:"mt-1",attrs:{"disabled":_vm.isLoading,"label":"Show Archived","hide-details":"","inset":""},model:{value:(_vm.showArchived),callback:function ($$v) {_vm.showArchived=$$v},expression:"showArchived"}}),_c(VBtn,{staticClass:"white ml-4",attrs:{"title":"Create Amp","href":_vm.newAnnouncementLink,"small":_vm.$vuetify.breakpoint.smAndDown}},[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp,"small":""}},[_vm._v(" plus ")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? 'Create Amp' : null)+" ")],1)],1)],1)],1),_c('announcements-list',{staticClass:"px-0",attrs:{"announcements":_vm.announcements,"loading":_vm.isLoading,"updating":_vm.isUpdating},on:{"update":_vm.update}}),_c('paging',{staticClass:"main-background px-0",attrs:{"total":_vm.total,"default-per-page":_vm.perPage,"loading":_vm.isUpdating}}),_c('training-section',{staticClass:"mt-10"}),_c('press-cable-user-welcome',{staticClass:"mt-10"}),(_vm.canSeeTrainingLink && !_vm.isLoading)?_c('training-link',{staticClass:"mt-4"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }